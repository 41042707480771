import React, { useState, useEffect } from 'react'
import Api2 from '../../utils/api2'
import { useHistory } from "react-router-dom"
import moment from 'moment'
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import { TextField, MenuItem, InputLabel, FormControl, Select } from '@mui/material'
import AppConstants from '../../utils/app-constants'
import { SHOW_SUCCESS_NOTIFICATION } from '../../utils/app-util'
import PatientInfoHeader from './PatientInfoHeader'

const RequestDoctorvisit = () => {
    const [patientData, setPatientData] = useState()
    const [visitRequestsdata, setvisitRequestsData] = useState([])
    const [isUpdated, setIsUpdated] = useState(false)
    const [selectedOption, setSelectedOption] = useState('');
    const [doctorlist, setDoctorlist] = useState([])

    let history = useHistory();

    const [open, setOpen] = useState(false);
    const [textValue, setTextValue] = useState('');
    const handleOpen = () => {
        setOpen(true);
        setTextValue("")
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleTextChange = (e) => {
        setTextValue(e.target.value);
    };

    useEffect(() => {

        const UserString = localStorage.getItem("USER")
        const userObject = JSON.parse(UserString)


        const patientSlug = userObject?.slug;

        // Only make the API call if it hasn't been made before

        Api2.get(`/admit/patient/${patientSlug}?withDoctorList=${true}`).subscribe((resp) => {
            if (resp) {
                console.log('doctors list', resp?.data?.data?.otherDoctorsList);
                setDoctorlist(resp?.data?.data?.otherDoctorsList)
            }
        })

        const admitPatient = async () => {
            await Api2.get(`/admit/patient/${patientSlug}`).subscribe((resp) => {
                if (resp) {

                    setPatientData(resp?.data?.data);
                    console.log('patient id ', patientData?.id);
                }
            }, (error) => {
                console.error(error)
            }
            );
        }

        const visitRequest = async () => {
            await Api2.get(`/admit/visit/request?admitId=${patientData?.id}`).subscribe((response) => {
                if (response) {
                    console.log('visitrequests', response?.data?.data);
                    setvisitRequestsData(response?.data?.data)
                }
            }, (error) => {
                console.error(error)
            }
            )
        }




        admitPatient();
        visitRequest();





    }, [patientData?.id, isUpdated])



    const handleCompleted = (id) => {

        Api2.put(`/admit/visit/request/status`, {
            id: id,
            admitId: patientData?.id,
            status: 'Completed'
        }).subscribe((response) => {
            if (response) {
                console.log('data completed')
                setIsUpdated(true)
                SHOW_SUCCESS_NOTIFICATION(AppConstants.MSG.UPDATE_SUCCESS)


            }
        }, (error) => {
            console.log(error);
            setIsUpdated(false)
        }
        )

    }


    const handleSubmitReqDoctorVisit = () => {
        // Handle form submission here
        const obj = {
            admitId: patientData?.id,
            description: textValue,
            doctorSlug: ""
        }
        Api2.post("/admit/visit/request/add", obj).subscribe((response) => {
            console.log('checking response', response)
            if (response?.data?.status) {
                // alert('saved successfully')
                setIsUpdated(true)
                SHOW_SUCCESS_NOTIFICATION(AppConstants.MSG.CREATE_SUCCESS)
            }
        }, (error) => {
            console.error(error)
            handleClose();
        }
        )

        console.log('Submitted:', textValue);
        handleClose();
    };

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };


    return (
        <section style={{ marginTop: "40px" }}>
            <div className='container'>
                <PatientInfoHeader patientData={patientData} />

                <div class="row d-flex ">
                    <div class="col-md-6">
                        <h1 class="mt-5 text-light">
                            Request Doctor Vist
                        </h1>

                    </div>
                    <div class="col-md-6 d-flex justify-content-end mt-5">
                        <div class="col-md-3 col-lg-3 col-sm-6  col-xs-6 col-6">
                            <button onClick={handleOpen} type="button" class="btn btn-outline-light  w-100 text-center" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                Add
                            </button>
                            <Dialog
                                open={open}
                                onClose={handleClose}
                                fullWidth
                                maxWidth="sm" // You can adjust the size (e.g., sm, md, lg, xl) as needed
                                PaperProps={{
                                    style: {
                                        minWidth: '800px', // Adjust the minimum width as needed
                                    },
                                }}
                            >
                                <DialogTitle>Doctor Visit Request</DialogTitle>
                                <DialogContent>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: "20px" }}>
                                        <div style={{ flex: '1' }}>
                                            <p style={{ textAlign: 'left' }}>Select Doctor</p>
                                        </div>
                                        <div style={{ flex: '1', textAlign: 'right' }}>
                                            {/* Dropdown or select component goes here */}
                                            <FormControl fullWidth>
                                                <InputLabel>Select an Option</InputLabel>
                                                <Select
                                                    value={selectedOption}
                                                    onChange={handleOptionChange}
                                                    label="Select an Option"
                                                >
                                                    {Array.isArray(doctorlist) && doctorlist?.map((doctor, index) => (
                                                        <MenuItem key={index} value={doctor.id}>
                                                            {doctor?.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <TextField
                                        label="Enter Text"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows={4}
                                        value={textValue}
                                        onChange={handleTextChange}
                                    />
                                </DialogContent>
                                <div style={{ padding: '16px', display: 'flex', justifyContent: 'center' }}>
                                    <Button variant="outlined" color="primary" onClick={handleSubmitReqDoctorVisit}>
                                        Submit
                                    </Button>
                                </div>
                            </Dialog>
                        </div>
                    </div>
                </div>



                <div class="row mt-5"><div class="table-responsive">
                    <table class="table"><thead>
                        {/* <tr style="border: rgb(62, 88, 122);"> */}
                        <tr style={{ border: "rgb(62,88,122)" }}>
                            <th class="text-center text-white">
                                <h5>Sr.No.</h5>
                            </th>
                            <th class="text-center text-white">
                                <h5>Doctor Name</h5>
                            </th>
                            <th class="text-center text-white">
                                <h5>Date</h5>
                            </th>
                            <th class="text-center text-white">
                                <h5>Status</h5>
                            </th>
                        </tr>
                    </thead>
                        <tbody>

                            {/* <tr style="border: rgb(62, 88, 122);"> */}
                            {
                                Array.isArray(visitRequestsdata) && visitRequestsdata?.map((row, index) => {
                                    return (
                                        <tr style={{ border: "rgb(62,88,122)" }}>
                                            <td class="text-center text-white">{index + 1}</td>

                                            <td class="text-center text-white"></td>
                                            <td class="text-center text-white">{moment(row?.createdAt).format("DD-MM-YYYY")}<br />{moment(row?.createdAt).format("hh:mm a")}
                                            </td>
                                            <td class="text-center text-white">
                                                {
                                                    row?.status == "Active" ? (
                                                        <button onClick={() => handleCompleted(row?.id)} class="btn btn-warning" id="Completed">Completed</button>
                                                    ) : (
                                                        <p>Done</p>
                                                    )
                                                }


                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>

                    </table>
                </div>
                </div>


            </div>

        </section>
    )
}

export default RequestDoctorvisit