import React from 'react';
import { useHistory } from "react-router-dom";
import Routes from '../../routes/routes';
import './IPDRoom.scss';
import boypic from "../../../src/assets/userpic.png"

const PatientInfoHeader = ({ patientData, showProfilePic }) => {
  let history = useHistory();

  return (
    <div className={`row custom-pt-6 ${showProfilePic ? 'show-profile-pic' : ''}`}>
      <div className="col-2">
        <div onClick={() => history.push(Routes.ROOM)} className="back-button">
          <i className="fa fa-arrow-left text-white"></i>
        </div>
      </div>
      {showProfilePic && (
        <div className="col-2 profile-pic-container">
          <div className="circle1">
            {
              patientData?.patient?.profilePicContent ? (
                <img className='circle1pic' src={patientData?.patient?.profilePicContent?.content} alt="Patient Pic" />
              ) : (
                <img className='circle1pic' src={boypic} alt="Default Pic" />
              )
            }
          </div>
        </div>
      )}
      <div className={showProfilePic ? "col-4 patient-info" : "col-6 patient-info"}>
        <p className="text-white fs-6">
          {patientData?.patient?.name} <br />
          {patientData?.patient?.age != null || patientData?.patient?.gender != null ? (
            <>
              {patientData?.patient?.age != null ? patientData?.patient?.age : ''}{patientData?.patient?.age != null && patientData?.patient?.gender != null ? '/' : ''}{patientData?.patient?.gender != null ? patientData?.patient?.gender : ''}
            </>
          ) : null}
          <br />
          {patientData?.doctor?.name} <br /> Diagnosis: {patientData?.prov}
          <br />
          {patientData?.patient?.allergies ? (
            <>
              Allergies: {patientData?.patient?.allergies}
            </>
          ) : null}
        </p>
      </div>
      <div className={showProfilePic ? "col-4 text-end" : "col-4 text-end"}>
        <h1 className="fw-bold h1size text-white">{patientData?.roomNumber}</h1>
        <h6 className="text-white uhid-wrap">{patientData?.patient?.uhid}</h6>
      </div>
    </div>
  );
};

export default PatientInfoHeader;