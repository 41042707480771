import React, { useState, useEffect, useContext } from "react";
import AllRoutes from "./routes/all-routes";
import "react-notifications/lib/notifications.css";
import "react-big-calendar/lib/sass/styles.scss";
import "./App.scss";
import {
  MuiThemeProvider,
  createMuiTheme,
  CircularProgress,
  Box
} from "@material-ui/core";
import AuthService from "./utils/services/auth-service";
import Api from "./utils/api";
import { height } from "@mui/system";
import { es } from "date-fns/locale";
import SampleContextProvider from "./contexts/SampleContext";




const App = () => {

  const [tenant, setTenant] = useState(null);
  var back1;
  var back2;

  useEffect(() => {
    Api.get("tenant").subscribe(resp => {
      AuthService.setTenant(resp.data.data);
      document.title = resp.data.data?.name;
      const favicon = document.getElementById("favicon");
      favicon.href = resp.data.data?.favicon;

      const metadesp = document.getElementById("metadesp");
      metadesp.content = resp.data.data?.meta_desp;

      const metatitle = document.getElementById("metatitle");
      metatitle.content = resp.data.data?.meta_title;
      
      const metakeyword = document.getElementById("metakeyword");
      metakeyword.content = resp.data.data?.meta_keyword;

      
      setTenant(resp?.data?.data);
      const type = "primary";
      if(resp.data.data?.current_theme == type){
        var back1 = {
          color: resp.data.data?.theme_light_2,
          backgroundColor: resp.data.data?.theme_light_1,
          
        };
        document.getElementById("body").style.backgroundColor = resp.data.data?.theme_light_1;
        document.getElementById("body").style.minHeight = "100vh";
        document.getElementById("body").style.margin = "0px";
        var back2 = {
          color: resp.data.data?.theme_light_4,
          backgroundColor: resp.data.data?.theme_light_3,
        };
      }
      else{
        var back1 = {
          color: resp.data.data?.theme_dark_2,
          backgroundColor: resp.data.data?.theme_dark_1,
        };
        
        document.getElementById("body").style.backgroundColor = resp.data.data?.theme_dark_1;
        document.getElementById("body").style.minHeight = "100vh";
        document.getElementById("body").style.margin = "0px";

        var back2 = {
          color: resp.data.data?.theme_dark_4,
          backgroundColor: resp.data.data?.theme_dark_3,
        };
      }

      

    });
  }, []);



  // const theme2 = (theme,font) => {
  //   const type = "primary";
  //   var col1 = "";
  //   var col2 = "";
  //   var col3 = "";
  //   var col4 = "";
  
  //   if (type === tenant?.current_theme){
  //     col1=theme.primary.light;
  //     col2=theme.primary.main;
  //     col4=theme.primary.contrastText;
  //     col3=theme.primary.dark;
  //   }
  //   else{
  //     col1=theme.secondary.light;
  //     col2=theme.secondary.main;
  //     col3=theme.secondary.dark;
  //     col4=theme.secondary.contrastText;
  //   }

  //   var myDynamicManifest = {
  //     "name": tenant?.name,
  //     "short_name": tenant?.name,
  //     "theme_color": col2,
  //     "background_color": col2,
  //     "display": "standalone",
  //     "orientation": "portrait",
  //     "scope": "/",
  //     "start_url": "/",
  //     "icons": [
  //       {
  //         "src": tenant?.logo,
  //         "type": "image/png",
  //         "sizes": "192x192"
  //       },
  //       {
  //         "src": tenant?.logo,
  //         "type": "image/png",
  //         "sizes": "512x512"
  //       }
  //     ]
  //   }

  //   const stringManifest = JSON.stringify(myDynamicManifest);
  //   const blob = new Blob([stringManifest], {type: 'application/json'});
  //   const manifestURL = URL.createObjectURL(blob);
  //   document.querySelector('#my-manifest-placeholder').setAttribute('href', manifestURL);


  
  //   theme["bg"] = "white";
  //   theme["textSecondary"] = col4;
  //   return createMuiTheme({
  //     overrides: {
  //       MuiButton: {
  //         root: {
  //           borderRadius: 10,
  //           textTransform: "capitalize",
  //           color:col4
  //         }
  //       },
  //       MuiButtonGroup: {
  //         root: {
  //           borderRadius: 10
  //         }
  //       }
  //     },
  //     palette: theme,
  //     typography: {
  //       fontFamily: font
  //     }
  //   });
  // };


  return (
    <div >
      {tenant ? (
         <SampleContextProvider>
          <div>

              <AllRoutes />

          </div>
          </SampleContextProvider>
        
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          height={"100vh"}
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default App;
