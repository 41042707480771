import React, { useState, useEffect } from 'react'
import Api2 from '../../utils/api2'
import { SHOW_SUCCESS_NOTIFICATION } from '../../utils/app-util'
import "./IPDRoom.scss"
import PatientInfoHeader from './PatientInfoHeader' // Import the new component

const InputOutputMonitor = () => {

  const [patientData, setPatientData] = useState()

  const [inputValue, setInputValue] = useState('');

  const [selectedValue, setSelectedValue] = useState('input');

  const [selectedButton, setSelectedButton] = useState(null);

  const [selectedCategory, setSelectedCategory] = useState('');

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    // Perform actions based on the selected value
    if (event.target.value === 'input') {
      // Do something for 'input'
    } else if (event.target.value === 'output') {
      // Do something for 'output'
    }
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    const UserString = localStorage.getItem("USER")
    const userObject = JSON.parse(UserString)
    const patientSlug = userObject?.slug;

    Api2.get(`/admit/patient/${patientSlug}`).subscribe((resp) => {
      if (resp) {
        console.log("test_rooms", resp?.data?.data)
        setPatientData(resp?.data?.data)
      }
    })
  }, [])

  const handlesubmit = (e) => {
    e.preventDefault()
    const userToken = localStorage.getItem('usersdatatoken');
    const userName = localStorage.getItem('userName');
    let obj = {
      admitId: patientData?.id,
      inputOutputType: selectedValue,
      valueType: selectedButton,
      volume: inputValue
    }

    Api2.post(`/admit/inputoutput/add`, obj ,{ headers: { useStaffToken: userToken } }).subscribe((response) => {
      if (response?.data?.status) {
        SHOW_SUCCESS_NOTIFICATION("Successfully Updated")
      }
      window.history.back();
    }, (error) => {
      console.error(error)
    })
  }

  return (
    <section className="input-output-monitor">
      <div className='container'>
        <PatientInfoHeader patientData={patientData} showProfilePic={true} /> {/* Use the new component */}
        <div className="card mt-4 p-4 text-dark">
          <h2 className="mb-4">Add {selectedValue.charAt(0).toUpperCase() + selectedValue.slice(1)}</h2>
          <div className="row g-3 align-items-end">
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="inputOutputSelect" className="form-label">Type:</label>
                <select
                  id="inputOutputSelect"
                  className="form-select form-select-lg bg-light text-dark border-secondary"
                  value={selectedValue}
                  onChange={handleChange}
                >
                  <option value="input">Input</option>
                  <option value="output">Output</option>
                </select>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="categorySelect" className="form-label">Category:</label>
                <select
                  id="categorySelect"
                  className="form-select form-select-lg bg-light text-dark border-secondary"
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                >
                  <option value="">Select category</option>
                  {(selectedValue === "input" ? inputCategories : outputCategories).map((category) => (
                    <option key={category} value={category}>{category}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="volumeInput" className="form-label">Volume:</label>
                <div className="input-group">
                  <input
                    type="number"
                    id="volumeInput"
                    className="form-control form-control-lg bg-light text-dark border-secondary"
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder="Enter volume"
                  />
                  <span className="input-group-text bg-light text-dark border-secondary">mL</span>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <button onClick={handlesubmit} className="btn btn-primary btn-lg custom-button">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const inputCategories = ['Oral Intake', 'Normal Saline', 'Ringer Lactate', 'Dextrose 5%', 'Blood', 'Others'];
const outputCategories = ['Urine', 'Naso Gastric', 'Faeces', 'Drain', 'Drain 2', 'Chest Tube', 'Others'];

export default InputOutputMonitor