import React, { useState, useEffect } from 'react'
import Api2 from '../../utils/api2'
// import boypic from "../../../src/assets/boy.png"

import { useHistory } from "react-router-dom"
import PatientInfoHeader from './PatientInfoHeader'

const MyMedication = () => {

  const [patientData, setPatientData] = useState()
  const [medicationsdata, setmedicationdata] = useState([])

  let history = useHistory();

  useEffect(() => {
    const UserString = localStorage.getItem("USER")
    const userObject = JSON.parse(UserString)
    const patientSlug = userObject?.slug;

    // Only make the API call if it hasn't been made before

    Api2.get(`/admit/patient/${patientSlug}`).subscribe((resp) => {
      if (resp) {
        console.log("test_rooms", resp?.data?.data?.medications);
        setPatientData(resp?.data?.data);
        setmedicationdata(resp?.data?.data?.medications)
      }
    });
  }, [])

  return (
    <section style={{ marginTop: "40px" }}>
      <div className='container'>
        <PatientInfoHeader patientData={patientData} />
        <div class="row">
          <h1 class="mt-4 text-light">
            My Medications
          </h1>
          <div class="col-lg-12 col-md-12 col-sm-12 col-12 col-xs-12">
            <table class="table table-bordered text-light">
              <thead>
                <tr>

                  <th scope="col">S.N</th>
                  <th scope="col">Name</th>


                  <th scope="col">Interval</th>
                  <th scope="col">Duration</th>
                  <th scope="col">Remarks</th>
                </tr>
              </thead>

              <tbody>
                {
                  Array.isArray(medicationsdata) && medicationsdata?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index+1}</td>
                        <td>{item?.name}&nbsp; {item?.dose}</td>
                        <td>{item?.frequency}</td>
                        <td>{`${item?.num} ${item?.interval}`}</td>
                        <td>{item?.remarks}</td>
                      </tr>
                    )
                  })
                }

              </tbody>
            </table>
          </div>
        </div>





      </div>
    </section>
  )
}

export default MyMedication