import { useQuery } from "@tanstack/react-query";
import Api2 from "../utils/api2";

export const useAdmitStatus = () => {
  return useQuery({
    queryKey: ["admitStatus"],
    queryFn: async () => {
      const response = await Api2.get(
        `${process.env.REACT_APP_API_BASE_URL1}/admit/status`
      ).toPromise();
      const statusNames = response.data?.data
        .filter((statusObj) => statusObj.status === "Active")
        .map((statusObj) => statusObj.name);
      return statusNames;
    },
    onError: (error) => {
      console.error("Error fetching admit status:", error);
    },
  });
};
