import React, { useState, useEffect, useRef, useCallback } from 'react'
import Api2 from '../../utils/api2'
import moment from 'moment'
import PatientInfoHeader from './PatientInfoHeader'
import CircularProgress from '@material-ui/core/CircularProgress'
import './IPDRoom.scss'

const ViewIochart = () => {

  const [patientData, setPatientData] = useState()
  const [admitHistorydata, setAdmithistorydata] = useState([])
  const [totalInput, settotalInput] = useState(0)
  const [totalOutput, settotalOutput] = useState(0)
  const [totalelements, setTotalElements] = useState(0)
  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(true)
  const [hasMore, setHasMore] = useState(true)
  const [totalPages, setTotalPages] = useState(0)
  const observer = useRef()

  // Get the patient data from the API
  useEffect(() => {
    const UserString = localStorage.getItem("USER")
    const userObject = JSON.parse(UserString)
    const patientSlug = userObject?.slug;

    const admitPatient = async () => {
      await Api2.get(`/admit/patient/${patientSlug}`).subscribe((resp) => {
        if (resp) {
          console.log("test_rooms", resp?.data?.data);
          setPatientData(resp?.data?.data);
          console.log('patient id ', patientData?.id);
        }
      });
    }
    admitPatient();
  }, [])

  // Get the admit history from the API
  const admithistory = async (page) => {
    setLoading(true)
    await Api2.post(`/admit/history/search`, {
      size: 10,
      page: page,
      filterModel: {
        admitId: {
          type: "equals",
          value: patientData?.id
        },
        inputOutput: {
          type: "custom",
          value: "exists"
        }
      },
      sortModel: []
    }).subscribe((response) => {
      if (response) {
        let total = response?.data?.data?.content;
        setTotalElements(response?.data?.data?.totalElements)
        setTotalPages(response?.data?.data?.totalPages)
        
        let filteredDataforinput = total.filter(item => item.inputOutput.inputOutputType === 'input');
        let filteredDataforoutput = total.filter(item => item.inputOutput.inputOutputType === 'output');

        let totalInput = filteredDataforinput.reduce((acc, curr) => {
          return acc + curr.inputOutput.volume
        }, 0)
        let totalOutput = filteredDataforoutput.reduce((acc, curr) => {
          return acc + curr.inputOutput.volume
        }, 0)

        settotalInput(prev => prev + totalInput)
        settotalOutput(prev => prev + totalOutput)
        setAdmithistorydata(prev => [...prev, ...response?.data?.data?.content])
        setHasMore(page < response?.data?.data?.totalPages)
        setLoading(false)
      }
    }, (error) => {
      console.log(error);
      setLoading(false)
    })
  }

  useEffect(() => {
    if (patientData?.id) {
      admithistory(0); // Make the initial API call with page 0
    }
  }, [patientData?.id])

  useEffect(() => {
    if (page > 0) {
      admithistory(page);
    }
  }, [page])

  const lastElementRef = useCallback(node => {
    if (loading) return
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prevPage => prevPage + 1)
      }
    })
    if (node) observer.current.observe(node)
  }, [loading, hasMore])

  return (
    <section>
      <div style={{ marginBottom: "4vh" }} className='container'>
        <PatientInfoHeader patientData={patientData} />        
        {loading && page === 0 ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress color='black'/>
          </div>
        ) : (
          <>
            <div class="table-responsive custom-scrollbar" style={{ maxHeight: '60vh', overflowY: 'auto' }}>
              <table class="table" style={{marginBottom: 0}}>
                <thead style={{ position: 'sticky', top: 0, backgroundColor: '#fff' }}>
                  <tr style={{ border: "rgb(62,88,122)" }}>
                    <th class="text-center">
                      <h5>Time</h5></th>
                    <th class="text-center">
                      <h5>Input</h5>
                    </th>
                    <th class="text-center">
                      <h5>Output</h5>
                    </th>
                    <th class="text-center">
                      <h5>Logged By</h5>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {admitHistorydata.length === 0 ? (
                    <tr>
                      <td colSpan="4" class="text-center">No data available</td>
                    </tr>
                  ) : (
                    Array.isArray(admitHistorydata) && admitHistorydata.map((row, index) => {
                      if (admitHistorydata.length === index + 1) {
                        return (
                          <tr ref={lastElementRef} style={{ border: "rgb(62,88,122)" }}>
                            <td class="text-center">
                              {moment(row?.createdAt).format("hh:mm a")}<br />{moment(row?.createdAt).format("DD-MM-YYYY")}
                            </td>
                            <td class="text-center">{row?.inputOutput?.inputOutputType === "input" ? (row?.inputOutput?.volume + row?.inputOutput?.unit) : ("")}<br />{row?.inputOutput?.inputOutputType === "input" ? (row?.inputOutput?.valueType) : ("")}</td>
                            <td class="text-center">{row?.inputOutput?.inputOutputType === "output" ? (row?.inputOutput?.volume + row?.inputOutput?.unit) : ("")}<br />{row?.inputOutput?.inputOutputType === "output" ? (row?.inputOutput?.valueType) : ("")}</td>
                            <td class="text-center">{row?.createdByName ?? 'NA'}</td>
                          </tr>
                        )
                      } else {
                        return (
                          <tr style={{ border: "rgb(62,88,122)" }}>
                            <td class="text-center">
                              {moment(row?.createdAt).format("hh:mm a")}<br />{moment(row?.createdAt).format("DD-MM-YYYY")}
                            </td>
                            <td class="text-center">{row?.inputOutput?.inputOutputType === "input" ? (row?.inputOutput?.volume + row?.inputOutput?.unit) : ("")}<br />{row?.inputOutput?.inputOutputType === "input" ? (row?.inputOutput?.valueType) : ("")}</td>
                            <td class="text-center">{row?.inputOutput?.inputOutputType === "output" ? (row?.inputOutput?.volume + row?.inputOutput?.unit) : ("")}<br />{row?.inputOutput?.inputOutputType === "output" ? (row?.inputOutput?.valueType) : ("")}</td>
                            <td class="text-center">{row?.createdByName ?? 'NA'}</td>
                          </tr>
                        )
                      }
                    })
                  )}
                </tbody>
              </table>
            </div>

            <div class="container pt-3 background" style={{ backgroundColor: "#e9e6e6" }}>
              <div class="row text-center text-dark">
                <div class="col-md-6 col-lg-6 col-sm-6 col-6 col-xs-6">
                  <h5>Total Input</h5>
                  <p>{totalInput} ml</p>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-6 col-6 col-xs-6">
                  <h5>Total Output</h5>
                  <p> {totalOutput} ml</p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  )
}

export default ViewIochart