import { useQuery } from "@tanstack/react-query";
import Api from "../utils/api";

export const useProfile = (appointSlug) => {
    return useQuery({
        queryKey: ["profile"],
        queryFn: async () => {
            const response = await Api.get(`getprofile?id=${appointSlug}`).toPromise();
            return response.data;
        },
        onError: (error) => {
            console.error("Error fetching profile:", error);
        }
    })
}