import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import clsx from "clsx";
import { Container, Box, Typography } from "@material-ui/core";
import AuthService from "../../utils/services/auth-service";
import useGlobalStyles from "../../utils/global-style";
import Routes from "../../routes/routes";

import successIcon from "../../assets/green-check.svg";

const AppointmentConfirm = () => {
  const gClasses = useGlobalStyles();
  const tenant = AuthService.getTenant();
  const location = useLocation();
  const history = useHistory();
  const [timeLeft, setTimeLeft] = React.useState(5);

  useEffect(() => {
    // Timer countdown
    if (timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      history.replace(Routes.APPOINTMENT_LIST);
    }
  }, [timeLeft, history]);

  return (
    <div
      className={clsx(gClasses.minHeightRoot, gClasses.width)}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <Container
        style={{ flex: "1", display: "flex", flexDirection: "column" }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          style={{ flex: "1" }}
        >
          <img
            src={successIcon}
            alt="success icon"
            style={{ marginTop: "-100px" }}
          />
          <Typography variant="h5" style={{ marginTop: "27px", color: "#FFFFFF" }}>
            Thank You
          </Typography>
          <Typography
            variant="h6"
            style={{ fontSize: "18px", marginTop: "8px", color: "#FFFFFF" }}
          >
            Your appointment has been booked successfully
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "#FFFFFF", marginTop: "20px" }}
          >
            Redirecting in {timeLeft} seconds...
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "#FFFFFF", marginTop: "10px" }}
          >
            <span 
              onClick={() => history.replace(Routes.APPOINTMENT_LIST)}
              style={{ 
                textDecoration: "underline", 
                cursor: "pointer",
                color: "#4A90E2"
              }}
            >
              Click here
            </span> to view your appointments
          </Typography>
        </Box>
      </Container>
    </div>
  );
};

export default AppointmentConfirm;
