import React, { useState, useContext } from "react";
import { Redirect, useParams, useHistory } from "react-router-dom";
import Routes from "../../routes/routes";
import AgreeDialog from "../../screens/home/agree";
import { SampleContext } from "../../contexts/SampleContext";

const Mobilebutton = ({ doctor, AuthService, docid }) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const { back1, back2, button1 } = useContext(SampleContext);

  const back6 = {}
  back6.border = "none";
  back6.color = back2.color;
  back6.backgroundColor = back2.backgroundColor;

  const onDisclaimerAgreed = () => {
    setOpen(false);
    const val = doctor?.username ? doctor?.username : doctor?.slug;
    if (AuthService.isAuthenticated) {
      history.replace(`/appointment` + `/${val}`);
    } else {
      history.replace(`/login`);
    }
  };

  const clickLogin = () => {
    const val = doctor?.username ? doctor?.username : doctor?.slug
    history.push({
      pathname: Routes.LOGIN,
      state: val
    });
  };

  const clickLog = () => {
    history.push(Routes.LOGOUT);
  };

  const clickChat = () => {
    history.push({
      pathname: Routes.CHAT,
      state: {
        docSlug: docid,
      },
    });
  };

  const clickAppointment = () => {
    history.push(Routes.APPOINTMENT_LIST);
  };

  const clickReport = () => {
    history.push(Routes.REPORT);
  };

  const clickProfile = () => {
    history.push(Routes.PROFILE);
  };

  return (
    <>
      {!AuthService.isAuthenticated && (
        <>
          <button
            type="button"
            className="btn btn-light px-4 py-2 fs-6 rounded-3 mx-2 my-2 "
            onClick={clickLogin}
            style={back6}
          >
            {" "}
            <i
              className="fa fa-sign-in"
              style={{ color: "gray", "margin-right": "12px" }}
              aria-hidden="true"

            ></i>
            Login
          </button>

          <button
            className="btn btn-primary px-4 py-2 fs-6 rounded-3 mx-2 my-2"
            type="button"
            style={button1}
            onClick={clickLogin}
          >
            Book Appointment
          </button>

        </>
      )}

      {AuthService.isAuthenticated && (
        <>
          <div className="">
            <div className="d-lg-none d-md-none d-block">
              <button
                className="btn btn-primary px-4 py-2 fs-6 rounded-3 mx-2 my-2"
                type="button"
                style={button1}
                onClick={(e) => setOpen(true)}
              >
                Book Appointment
              </button>
            </div>
            <AgreeDialog
              open={open}
              onDisclaimerAgreed={onDisclaimerAgreed}
              disclaimer={doctor?.terms}
            />
            <button
              type="button"
              className="btn btn-light px-4 py-2 fs-6 rounded-3 mx-2 my-2 "
              onClick={clickChat}
              style={back6}
            >
              {" "}
              <img src="images/chat.png" className="img-fluid pe-2" alt="" />
              Chat
            </button>

            <button
              type="button"
              className="btn btn-light px-4 py-2 fs-6 rounded-3 mx-2 my-2"
              onClick={clickReport}
              style={back6}
            >
              <img src="images/report.png" className="img-fluid pe-2" alt="" />
              Reports
            </button>

            <button
              type="button"
              className="btn btn-light px-4 py-2 fs-6 rounded-3 mx-2 my-2"
              onClick={clickAppointment}
              style={back6}
            >
              <img src="images/appoint.png" className="img-fluid pe-2" alt="" />
              My Appointments
            </button>

            {/* Disabling Prescription as it is not visible on desktop UI*/}
            {/* <button
              type="button"
              className="btn btn-light px-4 py-2 fs-6 rounded-3 mx-2 my-2 "
              onClick={clickAppointment}
              style={back6}
            >
              {" "}
              <img
                src="/images/Vector1.png"
                className="img-fluid pe-2"
                alt=""
              />
              Prescription
            </button> */}

            {doctor?.feedback && (
              <button
                type="button"
                className="btn btn-light px-4 py-2 fs-6 rounded-3 mx-2 my-2"
                style={back6}
              >
                <img src="images/feed.png" className="img-fluid pe-2" alt="" />
                <a
                  href={`${doctor?.feedback}`}
                  style={{ textDecoration: "none", color: "inherit" }}
                  target="_blank"
                >
                  Feedback
                </a>
              </button>
            )}

            {doctor?.custommenu?.map((m, index) => {
              return (
                <>
                  <button
                    type="button"
                    className="btn btn-light px-4 py-2 fs-6 rounded-3 mx-2 my-2 "
                    style={back6}
                  >
                    {" "}
                    <img
                      src="images/chat.png"
                      className="img-fluid px-2"
                      alt="image"
                    />
                    <a
                      href={`${m.url}`}
                      style={{ textDecoration: "none", color: "inherit" }}
                      target="_blank"
                    >
                      {m.link}
                    </a>
                  </button>
                </>
              );
            })}

            <button
              type="button"
              className="btn btn-light px-4 py-2 fs-6 rounded-3 mx-2 my-2"
              onClick={clickProfile}
              style={back6}
            >
              <img src="images/Group.png" className="img-fluid pe-2" alt="" />
              My Profile
            </button>
            <button
              type="button"
              className="btn btn-light px-4 py-2 fs-6 rounded-3 mx-2 my-2"
              onClick={clickLog}
              style={back6}
            >
              <img src="images/log.png" className="img-fluid pe-2" alt="" />
              Logout
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default Mobilebutton;
