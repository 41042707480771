import React, { useEffect, useState } from 'react'

import Api2 from '../../utils/api2'

import { Button, Box } from '@material-ui/core'
import { Rating } from '@material-ui/lab'
import { SHOW_SUCCESS_NOTIFICATION } from '../../utils/app-util'
import AppConstants from '../../utils/app-constants'
import Loader from '../../components/layout/loader'
import PatientInfoHeader from './PatientInfoHeader'

const Feedback = () => {
    const [patientData, setPatientData] = useState()
    const [rating, setRating] = useState(0);
    const [comments, setComments] = useState('')
    const [loader, setLoader] = useState(false)


    const handleRatingChange = (event, newRating) => {
        setRating(newRating);
    };
    console.log('ratings', rating);

    const handleCommentsChange = (event) => {
        setComments(event.target.value);
    };

    useEffect(() => {
        setLoader(true)
        const UserString = localStorage.getItem("USER")
        const userObject = JSON.parse(UserString)


        const patientSlug = userObject?.slug;

        Api2.get(`/admit/patient/${patientSlug}`).subscribe((resp) => {
            if (resp) {
                console.log("test_rooms", resp?.data?.data)

                setPatientData(resp?.data?.data)
                setLoader(false)


            }

        })



    }, [])



    const handleSubmit = (e) => {
        e.preventDefault();
        const slug = localStorage.getItem("userSlug")
        const obj = {
            appointmentSlug: patientData?.appointmentSlug,
            patientSlug: patientData?.patientSlug,
            rating: rating,
            remark: comments,
            staffSlug: slug
        }


        Api2.post(`/admit/feedback/add`, obj).subscribe((response) => {
            if (response?.data?.status) {

                SHOW_SUCCESS_NOTIFICATION(AppConstants.MSG.UPDATE_SUCCESS)
            }
        }, (error) => {
            console.error(error)
        }
        )

    }









    return (
        loader ? (<Loader/>) : (
            <section>
                <div className='container'>
                    <PatientInfoHeader patientData={patientData} showProfilePic={true} />
                    <div className='row pt-5'>
                        <div className="col-md-6 col-lg-6 col-sm-6 col-6 col-xs-6">
                            {/* Star rating component */}
                            <Box component="fieldset" borderColor="transparent">
                                <h1 className='text-light pt-5'>Rating the Staff</h1>
                                <Rating
                                    className='pt-2 fs-1'
                                    name="rating"
                                    value={rating}
                                    onChange={handleRatingChange}
                                    size="large"
                                />
                            </Box>
                        </div>
                    </div>
                    <div className='row pt-5'>
                        <div className="col-md-10 col-lg-10 col-sm-6 col-6 col-xs-6">
                            <h1 className='text-light pt-5 pb-3'>Provide Feedback</h1>
                            {/* Textarea for user comments */}
                            <textarea
                                // className='pt-2'
                                rows={5}
                                style={{
                                    width: '100%',
                                    resize: 'vertical',
                                    borderRadius: '8px', // Add border-radius
                                    background: 'transparent', // Make the background transparent
                                    color: 'white', // Inherit text color from parent
                                    border: '1px solid #ccc', // Add a border
                                }}

                                placeholder="This feedback will only be shared with management"

                                value={comments}
                                onChange={handleCommentsChange}
                            />

                            {/* Submit button */}
                            <Button onClick={handleSubmit} variant="contained" color="primary" style={{ marginTop: '16px' }}>
                                Submit
                            </Button>
                        </div>

                    </div>


                </div>
            </section >
        )
    )
}

export default Feedback