import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import PatientInfoHeader from './PatientInfoHeader'
import Api2 from '../../utils/api2'

import { SHOW_SUCCESS_NOTIFICATION } from '../../utils/app-util'
import AppConstants from '../../utils/app-constants'

const VitalsMeasurement = ({ history }) => {
    const [patientData, setPatientData] = useState()

    const [formData, setFormData] = useState({
        temperature: '',
        temperature1: '',
        pulserate: '',
        tempraturmin: '',
        farenheitvalue: '',
        firevalue: '',
        smilevalue: '',
        textareavalue: ''
    });


    const handleTemperatureChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    useEffect(() => {
        const UserString = localStorage.getItem("USER")
        const userObject = JSON.parse(UserString)
        const patientSlug = userObject?.slug;

        Api2.get(`/admit/patient/${patientSlug}`).subscribe((resp) => {
            if (resp) {
                setPatientData(resp?.data?.data)
            }
        })
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent the default form submission behavior
        const userToken = localStorage.getItem('usersdatatoken');
        const userName = localStorage.getItem('userName');
        // Access form data from formData object
        const { temperature, temperature1, pulserate, tempraturmin,
            farenheitvalue,
            firevalue,
            smilevalue,
            textareavalue
        } = formData;

        let object = {
            admitId: patientData?.id,
            bloodPressure: `${temperature}/${temperature1}`,
            oxygenSaturation: tempraturmin,
            ps: smilevalue,
            pulseRate: pulserate,
            remarks: textareavalue,
            respirationRate: firevalue,
            temperature: farenheitvalue
        }

        Api2.post(`/admit/vitals/add`, object, { headers: { useStaffToken: userToken } })
            .subscribe((response) => {
                if (response?.data?.status) {
                    // alert('saved successfully')
                    SHOW_SUCCESS_NOTIFICATION(AppConstants.MSG.CREATE_SUCCESS)
                    // Navigate back one page
                    history.goBack()
                }
            }, (error) => {
                console.error(error)
            }
            )

        // You can perform actions with the form data here, such as sending it to a server

        // Reset the form fields
        setFormData({
            temperature: '',
            temperature1: '',
            pulserate: '',
            tempraturmin: '',
            farenheitvalue: '',
            firevalue: '',
            smilevalue: '',
            textareavalue: ''
        });
    };

    return (
        <section className='mb-4'>
            <div className="container">
                <PatientInfoHeader patientData={patientData} showProfilePic={true} />
                
                <div className="faicon pt-3 mt-4">
                    <div className="d-flex align-items-center mb-3">
                        <i className="fa-solid fa-temperature-full text-light fs-1 me-3" style={{width: '40px'}}></i>
                        <div className="d-flex flex-grow-1">
                            <input
                                type="text"
                                name='temperature'
                                value={formData.temperature}
                                onChange={handleTemperatureChange}
                                className="form-control bg-transparent text-light border border-light me-2"
                            />
                            <input
                                type="text"
                                name='temperature1'
                                value={formData.temperature1}
                                onChange={handleTemperatureChange}
                                className="form-control bg-transparent text-light border border-light me-2"
                            />
                            <p className="text-light mb-0 d-flex align-items-center">mmHg</p>
                        </div>
                    </div>
                    <div className="d-flex align-items-center mb-3">
                        <i className="fa-solid fa-heart-pulse text-light fs-1 me-3" style={{width: '40px'}}></i>
                        <div className="d-flex flex-grow-1">
                            <input
                                type="text"
                                name='pulserate'
                                value={formData.pulserate}
                                onChange={handleTemperatureChange}
                                className="form-control bg-transparent text-light border border-light me-2"
                            />
                            <p className="text-light mb-0 d-flex align-items-center">Per Minute</p>
                        </div>
                    </div>
                    <div className="d-flex align-items-center mb-3">
                        <i className="fa-solid fa-temperature-full text-light fs-1 me-3" style={{width: '40px'}}></i>
                        <div className="d-flex flex-grow-1">
                            <input
                                type="text"
                                name='tempraturmin'
                                value={formData.tempraturmin}
                                onChange={handleTemperatureChange}
                                className="form-control bg-transparent text-light border border-light me-2"
                            />
                            <p className="text-light mb-0 d-flex align-items-center">Degree Fahrenheit</p>
                        </div>
                    </div>
                    <div className="d-flex align-items-center mb-3">
                        <i className="fa-solid fa-lungs text-light fs-2 me-3" style={{width: '40px'}}></i>
                        <div className="d-flex flex-grow-1">
                            <input
                                value={formData.farenheitvalue}
                                name='farenheitvalue'
                                onChange={handleTemperatureChange}
                                type="text"
                                className="form-control bg-transparent text-light border border-light me-2"
                            />
                            <p className="text-light mb-0 d-flex align-items-center">Per Minute</p>
                        </div>
                    </div>
                    <div className="d-flex align-items-center mb-3">
                        <i className="fa-solid fa-fire-flame-simple text-light fs-1 me-3" style={{width: '40px'}}></i>
                        <div className="d-flex flex-grow-1">
                            <input
                                value={formData.firevalue}
                                name='firevalue'
                                onChange={handleTemperatureChange}
                                type="text"
                                className="form-control bg-transparent text-light border border-light me-2"
                            />
                            <p className="text-light mb-0 d-flex align-items-center">%</p>
                        </div>
                    </div>
                    <div className="d-flex align-items-center mb-3">
                        <i className="fa-solid fa-face-smile text-light fs-1 me-3" style={{width: '40px'}}></i>
                        <div className="d-flex flex-grow-1">
                            <input
                                value={formData.smilevalue}
                                name='smilevalue'
                                onChange={handleTemperatureChange}
                                type="text"
                                className="form-control bg-transparent text-light border border-light me-2"
                            />
                            <p className="text-light mb-0 d-flex align-items-center">/10</p>
                        </div>
                    </div>
                </div>
                <h1 className="text-light pt-5">Remarks</h1>
                <div className="pt-2">
                    <textarea
                        value={formData.textareavalue}
                        name='textareavalue'
                        onChange={handleTemperatureChange}
                        rows="5"
                        className="bg-transparent w-100 text-light p-3 border border-light"
                        placeholder="Please mention any significant remarks"></textarea>

                    <button onClick={handleSubmit} className="btn btn-outline-light mt-3 w-40 text-center">Submit Record</button>
                </div>
            </div>
        </section>
    )
}

export default withRouter(VitalsMeasurement)