import React, { useContext } from "react";
import { Button, Typography } from "@mui/material";
import { CustomDialogBox } from "../common/CustomDialogBox";
import CustomActionButton from "../common/CustomActionButton";
import { darken, useTheme } from "@material-ui/core/styles";
import { SampleContext } from "../../contexts/SampleContext";

const CustomDeleteModal = ({ open, onClose, onConfirm, title, message }) => {
  const theme = useTheme();
  const {button1 } = useContext(SampleContext);
  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <CustomDialogBox
      open={open}
      dialogTitle={title}
      handleDialogOnClose={onClose}
      dialogActions={
        <>
          <CustomActionButton
            variant="outlined"
            onClick={onClose}
            buttonTitle="Cancel"
            sx={{
              backgroundColor: "white",
              color: "black",
              border: `1px solid ${theme?.palette?.primary?.main}`,
              "&:hover": {
                backgroundColor: darken("#fff", 0.1),
                color: "black",
              },
            }}
          />
          <Button
            variant="contained"
            onClick={handleConfirm}
            buttonTitle="Delete"
            style={button1}
          >
            Delete
          </Button>
        </>
      }
    >
      <Typography sx={{ fontSize: 16 }}>
        {message}
      </Typography>
    </CustomDialogBox>
  );
};

export default CustomDeleteModal;
