import React, { useState, useEffect, useContext } from "react";
import BgImg from "../../assets/bg2.svg";
import { Markup } from "interweave";
import { useHistory } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Grid,
  makeStyles,
  fade,
} from "@material-ui/core";
import CardTile from "../../components/layout/card-tile";
import Routes from "../../routes/routes";
import { Link } from "react-router-dom";
import AuthService from "../../utils/services/auth-service";
import Api from "../../utils/api";
import { SampleContext } from "../../contexts/SampleContext";
import './global.css';
import AppConstants from "../../utils/app-constants";

const Home = () => {
  let history = useHistory();
  const { back1, back2, back3 } = useContext(SampleContext);

  if (localStorage.getItem(AppConstants.AUTH_TOKEN_KEY)?.length > 0) {
    AuthService.validateToken().subscribe(
      (data) => {
        return null;
      },
      (error) => {
        AuthService.clearLocalStorage();
        history.replace(Routes.HOME)
      }
    );
  }

  const [specialities, setSpecialities] = useState([]);
  const tenant = AuthService.getTenant();
  const [loader, setLoader] = useState(true);
  const [active, setActive] = useState();

  useEffect(() => {
    // document.body.style.margin = "0px";
    // document.body.style.padding = "0px 0px 0px 0px";
    document.body.classList.remove('container');
    Api.get("/getspeciality?status=Active").subscribe((resp) => {
      setSpecialities(resp.data);
      setLoader(false);
    });

    Api.get("/getdoctor?active=true").subscribe((resp) => {
      setActive(resp.data);
    });

  }, []);

  return (
    <div className="container-fluid p-0 m-0" style={back1}>

      <div className="banner position-relative mb-5">
        <img
          src={tenant && tenant.banner}
          alt=""
          style={{
            width: "100%",
            opacity: "0.5",
            objectFit: "cover",
          }}
        />
        <h2
          className="text-light position-absolute text-center "
          style={{
            top: "60%",
            width: "-webkit-fill-available"
          }}
        >
          {tenant && tenant.banner_title}
        </h2>
      </div>
      <div className="container">
        <div className="mb-5">
          <h5 className="text-center mb-3"><b>About Us</b></h5>
          <p style={{ fontSize: "14px", padding: "10px" }}>
            <Markup content={tenant && tenant.aboutus} />
          </p>
        </div>

        {loader ? (
          <div class="d-flex justify-content-center">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <div className="mb-5">
              <h5 className="text-centeSampleContextr mb-4 px-4">
                <b>Find doctor in top specialities</b>
              </h5>

              <div className="row mb-4 p-2">
                {specialities &&
                  specialities.map((item, index) => (
                    <div
                      className="card mb-2 text-white col-md-4 col-6  ps-2 "
                      style={{
                        width: "11rem",
                        margin: "auto",
                        cursor: "pointer",
                        height: "11rem",
                        backgroundColor: back1.backgroundColor,
                        border: "none",
                        boxShadow: "none"
                      }}
                      onClick={() =>
                        window.location.replace(
                          Routes.DOCTOR + `?category=${item.slug}`
                        )
                      }
                    >
                      <img
                        src={item.pic}
                        className="card-img-top img-fluid rounded-start"
                        alt="..."
                        style={{ width: "69px", margin: "10px auto" }}
                      />
                      <div className="card-body p-0 text-center">
                        <span className="card-title" style={back1}>{item.name}</span>
                      </div>
                    </div>
                  ))}
              </div>


            </div>

            <div className="mb-5">
              <h5 className="text-centeSampleContextr mb-4 px-4">
                <b>Find doctor</b>
              </h5>


              <div className="row mb-4 p-2 row-cols-2 row-cols-lg-4 g-2 g-lg-3"

              >
                {active &&
                  active.map((item, index) => (
                    <div className="col-md-3">
                      <div
                        className="card mb-2 text-white p-2"
                        style={{
                          cursor: "pointer",
                          height: "200px",
                          backgroundColor: "#8080804d",
                          border: "none",
                          boxShadow: "none"
                        }}
                        onClick={() =>
                          window.location.replace(
                            `/${item?.username ? item?.username : item.slug}`
                          )
                        }
                      >
                        <img
                          src={item.profilePic ? item.profilePic : `images/user.png`}
                          className="card-img-top img-fluid"
                          alt="..."
                          style={{ width: "82px", margin: "0px auto", height: "82px", borderRadius: "50%", objectFit: "fill","marginBottom":"8px" }}
                        />
                        <div className="card-body p-0 text-center">
                          <h5 className="card-title" >{item.name}</h5>
                          <p className="card-text m-0" >{item?.speciality?.name}</p>
                          <p className="card-text" >{item?.registrationNo}</p>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Home;
















