import React, { useState, useEffect, useRef, useContext } from "react";
import { makeStyles } from "@material-ui/core";
import useGlobalStyles from "../../utils/global-style";
import { Form } from "informed";
import CustomDatePicker from "../../components/form/date-picker";

import Api from "../../utils/api";
import AuthService from "../../utils/services/auth-service";
import {
  useQuery,
  SHOW_INFO_NOTIFICATION,
  SHOW_ERROR_NOTIFICATION,
  DEFAULT_ERROR_CALLBACK,
} from "../../utils/app-util";
import { useHistory, useLocation } from "react-router-dom";
import DisclaimerDialog from "./disclaimer-dialog";
import AlertDialog from "../../components/layout/alert-dialog-doctor";
import AppConstants from "../../utils/app-constants";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import axios from "axios";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import { SampleContext } from "../../contexts/SampleContext";
import "./doctor-app.css";
import { useProfile } from "../../apis/ProfileAPI";
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => {
  var col1 = theme.palette.primary.light;
  var col2 = theme.palette.primary.main;
  var col3 = theme.palette.primary.dark;
  var col4 = theme.palette.primary.contrastText;

  return {
    mb: {
      marginBottom: theme.spacing(1),
    },
    slots: {
      backgroundColor: "grey",
    },
    mr: {
      color: "black",
    },
  };
});

const DoctorAppointment = () => {
  const { back1, back2, button1 } = useContext(SampleContext);
  const gClasses = useGlobalStyles();
  const classes = useStyles();
  const params = useParams();
  const [drTImings, setDrTimings] = useState([]);
  const doctor = AuthService.getTenant().selectedDoctor;
  const [doctor1, setDoctor] = useState();
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [disclaimer, setDisclaimer] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [minutes, setMinutes] = useState(null);
  const [docId, setDocId] = useState(null);
  const [appointmentId, setAppointmentId] = useState(null);

  const [selectedType, setSelectedType] = useState(null);
  const [date, setDate] = useState(null);
  const [fee, setFee] = useState(null);
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [slot, setSlot] = useState(null);
  const [todayDate, setTodayDate] = useState();
  const user = AuthService.getUser();
  const [appo, setAppo] = useState();
  const [loader, setLoader] = useState(true);
  const [slotid, setslotid] = useState();
  const [currentStep, setCurrentStep] = useState(1);

  let query = useQuery();

  const { data: profileData, isLoading: profileLoading } = useProfile(user?.id);

  const docSlug = query.get("slug");
  const formApi = useRef();
  const location = useLocation().pathname + useLocation().search || "/";
  var paymentlink = "";

  const { tempDate, tempTime, tempType } = useLocation().state || {};

  const history = useHistory();

  const [checked, setChecked] = useState(false);
  const [radioValue, setRadioValue] = useState();

  let geturl = "";
  const fetchTimeSlots = (docSlug, type, date) => {
    if (date == null) {
      const vad = format(new Date(), "yyyy-MM-dd");
      setDate(vad);
      geturl = `get_slots_of_doctor_by_date?slug=${docId}&date=${vad}&appointmentId=${type}`;
    } else {
      geturl = `get_slots_of_doctor_by_date?slug=${docId}&date=${date}&appointmentId=${type}`;
    }
    Api.get(geturl).subscribe((resp) => {
      const slots = (resp.data || {}).data || {};
      setSlot(slots);
      // formApi.current.setValue("fees", resp.data.data.fees);
    });
  };

  const redirectap = () => {
    if (!AuthService.isAuthenticated) {
      window.location.replace("/");
    }
  };
  redirectap();

  useEffect(() => {
    Api.get(`getprofile?id=${params.id}`).subscribe((resp) => {
      setLoader(false);
      const arr = resp.data.data || {} || {} || [];

      setDocId(arr.slug);
      setDoctor(arr);
      var addday = 30;
      var dayDate = new Date();
      if (arr?.futureBooking > -1) {
        addday = arr.futureBooking;
      }
      dayDate.setDate(dayDate.getDate() + addday);
      setTodayDate(format(dayDate, "yyyy-MM-dd"));

      if (arr && arr.slots) {
        let availSummaryObj = arr.slots.map((item) => {
          let newObj = {
            type: item.appointmentType,
            days: `${item.fromDay} - ${item.toDay}`,
            timings: `${item.fromTime} - ${item.toTime}`,
          };
          return newObj;
        });
        setDrTimings(availSummaryObj);
      }
      Api.get(`get_doctor_appointment_types?id=${arr.slug}`).subscribe(
        (resp) => {
          setAppointmentTypes(resp.data);
        }
      );
    });
  }, [docId]);

  const onTypeSelected = (e) => {
    if (e.target.value.length > 1) {
      let obj = JSON.parse(e.target.value);

      setAppo(e.target.value);
      setSelectedType(obj);
      fetchTimeSlots(docId, obj._id, date);
      setAppointmentId(obj._id);

      setIsActive(obj.status);
      if (obj.disclaimer && obj.status) {
        setDisclaimer(obj.disclaimer);
        setOpen(true);
      }
      setCurrentStep(3);
    }
  };

  const onSlotSelected = (e) => {
    let obj = JSON.parse(e.currentTarget.value);

    setRadioValue(obj.startTimeOnly);

    setSelectedTime(obj.startTimeString);
    setFee(obj.fee);
    setEndTime(obj.endTimeString);
    setMinutes(obj.minutes);
    setslotid(obj.slotId);
    formApi.current.setValue("fees", obj.fee);
  };

  const onDisclaimerAgreed = () => {
    setOpen(false);
  };

  const onDateSelected = (val) => {
    const dat = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(val);
    const vad = format(new Date(dat), "yyyy-MM-dd");
    setDate(vad);

    if (docId && selectedType) {
      fetchTimeSlots(docId, selectedType._id, vad);
      setCurrentStep(3);
    }
    setRadioValue(null);
    setSelectedTime(null);
    setFee(null);
    setEndTime(null);
    setMinutes(null);
    setslotid(null);
  };

  const handleSubmit = (val) => {
    if (!selectedType) {
      SHOW_INFO_NOTIFICATION(AppConstants.MSG.SELECT_APPOINTMENT_MSG);
      return;
    }
    if (!selectedTime) {
      SHOW_INFO_NOTIFICATION(AppConstants.MSG.SELECT_TIME_MSG);
      return;
    }
    localStorage.setItem(
      "appointment",
      JSON.stringify({
        doctorSlug: docSlug,
        date: date,
        time: selectedTime,
        fees: val.fee,
        type: selectedType?._id,
        patientSlug: user?.slug,
      })
    );
    // let isAuthenticated = false;
    if (AuthService.isAuthenticated) {
      // if (isAuthenticated) {
      const appnmntDto = {
        doctorSlug: docId,
        date: date,
        time: selectedTime,
        fees: fee,
        type: selectedType._id,
        patientSlug: user.slug,
        min: minutes,
        endt: endTime,
        name: user.name,
        mob: user.mobileNumber,
        appid: appointmentId,
        slotid: slotid,
      };
      bookAppointment(appnmntDto);
    } else {
      window.location.replace(
        `/login?redirectUrl=${window.location.origin}/home/callback&redirectPath=${location}&date=${date}&time=${selectedTime}&type=${selectedType.label}&fees=${val.fees}`
      );
    }
  };

  const previousDoc = () => {
    window.location.replace(`/${params.id}`);
  };

  const bookAppointment = async (data) => {
    const formData = new FormData();
    formData.append("startDateTime", data.time);
    formData.append("endDateTime", data.endt);
    formData.append("doctorSlug", data.doctorSlug);
    formData.append("patientSlug", data.patientSlug);
    formData.append("minutes", data.min);
    formData.append("fee", data.fees);
    formData.append("creatorName", data.name);
    formData.append("creatorRole", "PATIENT");
    formData.append("creatorNumber", data.mob);
    formData.append("from", "PATIENT");
    formData.append("appointmentTypeId", data.appid);
    formData.append("slotId", data.slotid);

    const token = AuthService.getToken();

    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_BASE_URL}/add_appointment`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "X-AUTH-TOKEN": token,
        },
      });
      if (response.data.status === true) {
        paymentlink = response.data.data._id;
        {
          paymentlink &&
            history.push({
              pathname: `/payment/${paymentlink}`,
              state: {
                appoSlug: response.data.data.slug,
                AddAppointmentorderId: response.data.data.orderId,
              },
            });
        }
      } else {
        SHOW_ERROR_NOTIFICATION(response.data.message);
      }
    } catch (error) {
      if (error.response?.status === 403) {
        SHOW_ERROR_NOTIFICATION(
          error.response.data.message + AppConstants.MSG.SESSION_EXPIRED
        );
      } else if (error.response?.data?.status === false) {
        SHOW_ERROR_NOTIFICATION(error.response.data.message);
      } else {
        DEFAULT_ERROR_CALLBACK(error);
      }
    }
  };

  useEffect(() => {
    let appointment = localStorage.getItem("appointment");
    if (appointment) {
      appointment = JSON.parse(appointment);
    } else {
      appointment = {};
    }
    if (tempDate) {
      const appnmntDto = {
        doctorSlug: "DCT1",
        date: tempDate,
        time: tempTime,
        fees: doctor.fees ?? appointment?.fees,
        type: tempType,
        patientSlug: user.slug,
      };
      bookAppointment(appnmntDto);
    }
  }, []);

  return (
    <>
      {loader ? (
        <div
          class="spinner-border text-primary d-flex justify-content-center"
          role="status"
          style={{ margin: "90px auto" }}
        >
          <span class="visually-hidden">Loading...</span>
        </div>
      ) : (
        <>
          <Form apiRef={formApi} onSubmit={handleSubmit}>
            <div className="container" style={back1}>
              <div
                className="row overflow-hidden"
                style={{ marginTop: "80px" }}
              >
                <main className="col-lg-12 col-md-12 col-sm-12">
                  <div className="text-start pt-3">
                    <h5>Appointment Booking</h5>
                    <hr className="mt-3 mx-auto" />
                  </div>

                  <div className="d-grid gap-1">
                    <h6> Step 1 :&nbsp; Choose your appointment Type </h6>

                    <select
                      className="mt-3 form-select form-control-lg back1"
                      aria-label="Default select example"
                      onChange={onTypeSelected}
                      value={appo}
                    >
                      <option value="">Select Appointment Type</option>
                      {appointmentTypes.map((item, index) => {
                        let disable = true;
                        let slotactive = doctor1?.slots;

                        if (slotactive !== undefined) {
                          {
                            slotactive.map((item1, i) => {
                              if (item1.appointmentType == item._id) {
                                if (item.status == "Active") {
                                  disable = false;
                                  item.disclaimer = item1.disclaimer;
                                }
                              }
                            });
                          }
                        }

                        return (
                          <option
                            value={JSON.stringify(item)}
                            disabled={disable}
                          >
                            {item.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                    {currentStep >= 2 && (
                      <>
                        <h6 className="mt-4">
                          Step 2 :&nbsp; Choose date of appointment{" "}
                        </h6>

                        <section className="mb-2">
                          <form className="row">
                            <label
                              for="date"
                              className="col-1 col-form-label"
                            ></label>
                            <div className="col-12">
                              <div className="input-group date mb-4" id="datepicker">
                                <CustomDatePicker
                                  disablePast
                                  disableToolbar
                                  className="input-bg"
                                  containerClassName="mr"
                                  variant="static"
                                  inputVariant="outlined"
                                  maxdate={todayDate}
                                  field="date"
                                  label="Appointment Date"
                                  onChange={onDateSelected}
                                  validateOnBlur
                                  validateOnChange
                                />
                              </div>
                            </div>
                        </form>
                      </section>
                    </>
                  )}
                  {(currentStep >= 3 && slot?.length > 0) ? (
                    <>
                      <h6 className="mb-4">
                        Step 3 :&nbsp; Choose time of appointment
                      </h6>
                      {profileData?.data?.isBlocked !== true && (
                        <div className="row">
                          <div className="col-md-8">
                            <ButtonGroup>
                              <div className="d-flex flex-wrap">
                                {slot.map((item, index) => (
                                  <div className="mx-2 mb-2 p-1" key={index}>
                                    <ToggleButton
                                      id={`item-${index}`}
                                      type="radio"
                                      variant={"outline-primary"}
                                      name="radio"
                                      disabled={!item.available}
                                      value={JSON.stringify(item)}
                                      checked={radioValue === item.startTimeOnly}
                                      onChange={onSlotSelected}
                                      style={{
                                        color: back1.color,
                                        borderColor: back1.color,
                                        textDecoration: !item.available
                                          ? "line-through"
                                          : "none",
                                      }}
                                    >
                                      {item.startTimeOnly}
                                    </ToggleButton>
                                  </div>
                                ))}
                              </div>
                            </ButtonGroup>
                          </div>
                        </div>
                      )}
                    </>
                  ) : currentStep >= 3 ? (
                    <Typography sx={{ fontWeight: "bold" }}>
                      No slots available for the selected date. Please try a different date.
                    </Typography>
                  ) : null}
                  <hr className="my-4 mx-auto" />
                  <section className=" col-lg-12 col-12 mb-3">
                    <div className="text-end">
                      <button
                        type="button"
                        className="btn btn-secondary px-4 mx-2"
                        onClick={previousDoc}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary px-4 "
                        style={button1}
                      >
                        Next
                      </button>
                    </div>
                  </section>
                  {/* Add the fees field here */}
                  {/* <input type="hidden" field="fees" /> */}
                </main>
              </div>
            </div>
          </Form>
          <DisclaimerDialog
            open={open}
            isActive={isActive}
            onDisclaimerAgreed={onDisclaimerAgreed}
            disclaimer={disclaimer}
          />
          <AlertDialog
            open={openAlert}
            handleClose={() => setOpenAlert(!openAlert)}
            title="Message"
            msg="This service is temporarily unavailable. Only AUDIO Appointment can be booked"
          />
        </>
      )}
    </>
  );
};

export default DoctorAppointment;
